<template>
    <div class="js-entry o-entry c-video-entry">
        <video-player
                v-if="$xp.evaInstalled"
                v-eva-media
                :source="$xp.settings.baseUrl + 'xpedeo/' + entryData.Video.Url"
                :binaryId="entryData.Video.BinaryID"
                :pageId="entryData.Video.BinaryID"

                :poster="posterUrl"
                :autoPlay="entryData.Json.AutoPlay"
                :loop="entryData.Json.Loop"
                :muted="entryData.Json.Muted"
                :showControls="entryData.Json.ShowControls"
                :subtitles="subtitles"
                :lipSyncUrl="(pageData.Class === 'XpVideoSync') ? pageData.lipSyncUrl : null">
        </video-player>
        <video-player
                v-else
                :source="$xp.settings.baseUrl + 'xpedeo/' + entryData.Video.Url"
                :binaryId="entryData.Video.BinaryID"
                :pageId="entryData.Video.BinaryID"

                :poster="posterUrl"
                :autoPlay="entryData.Json.AutoPlay"
                :loop="entryData.Json.Loop"
                :muted="entryData.Json.Muted"
                :showControls="entryData.Json.ShowControls"
                :subtitles="subtitles"
                :lipSyncUrl="(pageData.Class === 'XpVideoSync') ? pageData.lipSyncUrl : null">
        </video-player>
    </div>
</template>

<script>
import entryMixin from '../../mixins/entry'
import VideoPlayer from '../VideoPlayer.vue'

export default {
  mixins: [entryMixin],
  components: {
    'video-player': VideoPlayer
  },
  computed: {
    subtitles () {
      console.log('getSubtitles() ', this.entryData)
      if (this.entryData.Subtitles) {
        return {
          BinaryID: this.entryData.Subtitles.BinaryID,
          Url: (this.$xp.settings.baseUrl + 'xpedeo/' + this.entryData.Subtitles.Url),
          srclang: this.entryData.Subtitles.srclang,
          label: this.entryData.Subtitles.label
        }
      }
      return null
    },
    posterUrl () {
      if (this.entryData.VideoThumb.BinaryID > 0) {
        return this.$xp.content.getImageUrl(this.entryData.VideoThumb)
      } else {
        return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
      }
    }
  }
}
</script>
